<template>
  <div class="forget-model">
    <!--  标题  -->
    <div class="model-title">
      {{$t('forgetPassword.resetPassword')}}
    </div>

    <a-tabs v-model:active-key="activeLoginType" @change="handleChange">
      <!--    账号密码登录 Tab，判断是否禁用短信登录      -->
      <a-tab-pane key="withEmail" :tab="$t('forgetPassword.emailTab')"/>
      <a-tab-pane key="withPhone" :tab="$t('forgetPassword.phoneTab')" v-if="!disableSMSLogin"/>
    </a-tabs>
    <!--  表单  -->
    <div>
      <a-form
        ref="formRef"
        name="custom-validation"
        :model="forgetForm"
        :rules="forgetRules"
        v-bind="layout"
        class="forget-form"
        @finish="resetPassword"
      >
        <!--  邮箱  -->
        <a-form-item
          v-if="activeLoginType === 'withEmail'"
          :label="$t('forgetPassword.email')"
          name="email">
          <a-input
            v-model:value="forgetForm.email"
            autocomplete="off"
            :placeholder="$t('forgetPassword.emailTips')"/>
        </a-form-item>
        <!--  手机号码  -->
        <a-form-item
          v-if="activeLoginType === 'withPhone'"
          :label="$t('forgetPassword.phone')"
          name="phone">
          <a-input
            v-model:value="forgetForm.phone"
            autocomplete="off"
            :placeholder="$t('forgetPassword.phoneTips')"/>
        </a-form-item>

        <!--  验证码  -->
        <a-form-item
          :label="$t('forgetPassword.verificationCode')"
          name="verificationCode">
          <a-input
            v-model:value="forgetForm.verificationCode"
            autocomplete="off"
            :placeholder="$t('forgetPassword.verificationTips')">
            <template #suffix>
              <div class="code-input-suffix">
                <div class="divide-line"></div>
                <span
                  @click="getCode"
                  v-if="canGetCode"
                  class="get-code-text">
                  {{$t('forgetPassword.getCode')}}
                </span>
                <span
                  v-else
                  class="time-left-text">{{timeLeft}} {{$t('forgetPassword.second')}}</span>
              </div>
            </template>
          </a-input>
        </a-form-item>

        <!--  密码  -->
        <a-form-item
          :label="$t('forgetPassword.enterPassword')"
          name="password">
          <a-input-password
            v-model:value="forgetForm.password"
            autocomplete="off"
            :placeholder="$t('forgetPassword.passwordTips')"/>
        </a-form-item>

        <!--  确认密码  -->
        <a-form-item
          :label="$t('forgetPassword.confirmPassword')"
          name="confirmPassword">
          <a-input-password
            v-model:value="forgetForm.confirmPassword"
            autocomplete="off"
            :placeholder="$t('forgetPassword.passwordTips')"/>
        </a-form-item>

        <!--  重置密码 按钮  -->
        <a-form-item>
          <a-button type="primary" html-type="submit" style="width:100%;margin-top:10px" size="large">
            {{$t('forgetPassword.resetPassword')}}
          </a-button>
        </a-form-item>
      </a-form>

      <!--  返回登录页面 按钮  -->
      <div class="back-button" @click="hideForgetModal">
        {{$t('forgetPassword.backToLogin')}}
      </div>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, ref, toRefs } from 'vue'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'
import { message } from 'ant-design-vue'
import config from '@/config'
export default defineComponent({
  name: 'ForgetPassword',
  components: {},
  props: {},
  setup (props, context) {
    const { t } = useI18n()

    // 是否禁用短信相关逻辑(绑定邮箱, 绑定手机)
    const disableSMSLogin = computed(() => config.DISABLE_SMS_LOGIN === 'true')

    const formRef = ref(null)

    const state = reactive({
      forgetForm: {
        email: '',
        phone: '',
        verificationCode: '',
        password: '',
        confirmPassword: ''
      },
      canGetCode: true,
      timeLeft: 0,
      activeLoginType: 'withEmail'
    })

    onMounted(async () => {

    })

    const layout = {
      layout: 'vertical'
    }

    const emailValidate = async (rule, value) => {
      if (state.activeLoginType === 'withEmail') {
        var emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
        if (value === '') {
          return Promise.reject(new Error(t('forgetPassword.emailNullError')))
        } else if (!emailReg.test(value)) {
          return Promise.reject(new Error(t('forgetPassword.emailRuleError')))
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    }

    const phoneValidate = async (rule, value) => {
      if (state.activeLoginType === 'withPhone') {
        if (value === '') {
          return Promise.reject(new Error(t('forgetPassword.phoneTips')))
        } else if (!/^1\d{10}$/.test(value)) { // 是否满足「以1开头的11位数字」格式
          return Promise.reject(new Error(t('forgetPassword.phoneErrorTips')))
        } else {
          return Promise.resolve()
        }
      } else {
        return Promise.resolve()
      }
    }

    const codeValidate = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('forgetPassword.verificationTips')))
      } else {
        return Promise.resolve()
      }
    }

    const passwordValidate = async (rule, value) => {
      const passwordReg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,20}$/
      if (value === '') {
        return Promise.reject(new Error(t('forgetPassword.passwordTips')))
      } else if (!passwordReg.test(value)) {
      // } else if (value && value.length < 8) {
        return Promise.reject(new Error(t('forgetPassword.passwordRuleError')))
      } else {
        return Promise.resolve()
      }
    }

    const checkConfirm = async (rule, value) => {
      if (value === '') {
        return Promise.reject(new Error(t('forgetPassword.passwordTips')))
      } else if (value !== state.forgetForm.password) {
        return Promise.reject(new Error(t('forgetPassword.confirmPasswordErrorTips')))
      } else {
        return Promise.resolve()
      }
    }

    const forgetRules = {
      email: [{ validator: emailValidate, trigger: 'blur' }],
      phone: [{ validator: phoneValidate, trigger: 'blur' }],
      verificationCode: [{ validator: codeValidate, trigger: 'blur' }],
      password: [{ validator: passwordValidate, trigger: 'blur' }],
      confirmPassword: [{ validator: checkConfirm, trigger: 'blur' }]
    }

    const hideForgetModal = () => {
      context.emit('hideForgetModal')
    }

    let intervalId

    /**
     * 开启计时器
     */
    const startTimer = () => {
      if (intervalId) {
        clearInterval(intervalId)
        intervalId = undefined
      }
      state.canGetCode = false
      state.timeLeft = 60
      intervalId = setInterval(() => {
        state.timeLeft -= 1
        if (state.timeLeft <= 0) {
          state.canGetCode = true
          clearInterval(intervalId)
          intervalId = undefined
        }
      }, 1000)
    }

    /**
     * 获取验证码
     * @returns {Promise<void>}
     */
    const getCode = async () => {
      await formRef.value.validate(['email', 'phone'])
      if (state.activeLoginType === 'withPhone') {
        if (!/^1\d{10}$/.test(state.forgetForm.phone)) {
          return
        }
        // startTimer()
        const res = await api.getVerifyCode({ areaCode: '+86', number: state.forgetForm.phone })
        if (res && res.code === 200) {
          message.success(t('forgetPassword.getVerifyCodeSuccessTips'))
          startTimer()
        } else {
          if (res.detail) {
            message.error(res.detail)
          }
        }
      } else if (state.activeLoginType === 'withEmail') {
        var emailReg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/
        if (!emailReg.test(state.forgetForm.email)) {
          return
        }
        const res = await api.getVerifyCodeWithEmail({ email: state.forgetForm.email })
        if (res && res.code === 200) {
          message.success(t('forgetPassword.getVerifyCodeSuccessTips'))
          startTimer()
        } else {
          if (res.detail) {
            message.error(res.detail)
          }
        }
      }
    }

    /**
     * 重置密码
     */
    const resetPassword = async () => {
      if (state.activeLoginType === 'withPhone') {
        const res = await api.resetPassword({ code: state.forgetForm.verificationCode, areaCode: '+86', number: state.forgetForm.phone, password: state.forgetForm.password })
        if (res && res.code === 200) {
          message.success(t('forgetPassword.resetPasswordSuccessTips'))

          // 返回登录界面
          hideForgetModal()
        } else {
          if (res.detail) {
            message.error(res.detail)
          }
        }
      } else if (state.activeLoginType === 'withEmail') {
        const res = await api.resetPasswordWithEmail({ code: state.forgetForm.verificationCode, email: state.forgetForm.email, password: state.forgetForm.password })
        if (res && res.code === 200) {
          message.success(t('forgetPassword.resetPasswordSuccessTips'))

          // 返回登录界面
          hideForgetModal()
        } else {
          if (res.detail) {
            message.error(res.detail)
          }
        }
      }
    }

    const handleChange = () => {
      formRef.value.resetFields()
    }

    return {
      hideForgetModal,
      forgetRules,
      layout,
      getCode,
      resetPassword,
      handleChange,
      formRef,
      disableSMSLogin,
      ...toRefs(state)
    }
  }
})
</script>
<style lang='less' scoped>
.model-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 48px;
}
.forget-model {
  padding: 48px 48px 0;
  .code-input-suffix {
    min-width: 80px;
    text-align: center;
    .divide-line {
      border-left: solid 1px rgba(0,0,0,0.2);
      height: 30px;
      position: absolute;
      transform: translate(-10px, -50%);
      top: 50%;
    }
    .get-code-text {
      color: #209FFD;
      cursor: pointer;
    }
    .time-left-text {
      color: rgba(0,0,0,0.4);
      cursor: default;
    }
  }
}
.back-button {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  color: #209FFD;
  cursor: pointer;
}
</style>
<style lang="less">
.forget-form {
  .ant-form-item-control-input .ant-form-item-control-input-content{
    align-items: flex-end;
    min-height: 48px;
  }
  .ant-input{
    padding: 13px 0px;
    border-style: none none solid;
    border-radius: 0px;
    &:focus {
      box-shadow: 0 2px 4px -2px rgba(24, 144, 255, 0.2);
    }
  }
  .ant-input-affix-wrapper {
    padding: 13px 0px;
    border-style: none none solid;
    border-radius: 0px;
    &-focused {
      box-shadow: 0 2px 4px -2px rgba(24, 144, 255, 0.2);
    }
  }
  .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 0;
      line-height: 1.5715;
      white-space: initial;
      text-align: left;
  }
}
.forget-model {
  .ant-btn-lg {
    height: 48px;
    padding: 6.4px 12px;
    font-size: 16px;
    border-radius: 8px;
  }
}
</style>
