<template>
    <div class="login-model">
      <div class="login-title">{{$t(welcomeTip ? `login.${welcomeTip}` : 'login.loginTitle')}}</div>
      <div class="login-con">
        <a-tabs v-model:active-key="activeLoginType">
          <!--    账号密码登录 Tab，判断是否禁用短信登录      -->
          <a-tab-pane key="withEmail" :tab="$t('login.accountLogin')">
            <a-form
              class="login-form"
              ref="formRef"
              name="login-form"
              :model="loginInfo"
              :rules="loginRules"
              @finish="loginAction">
              <a-form-item name="user">
                <a-input
                  v-model:value="loginInfo.user"
                  autocomplete="off"
                  :placeholder="$t('login.enterAccount')"></a-input>
              </a-form-item>
              <a-form-item name="password">
                <a-input-password
                  v-model:value="loginInfo.password"
                  :placeholder="$t('login.pswInputTips')"></a-input-password>
              </a-form-item>
              <a-form-item>
                <a-button style="width:100%;margin-top:8px" size="large" type="primary" html-type="submit">{{$t('login.login')}}</a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>

          <!--    手机登录 Tab，判断是否禁用短信登录      -->
          <a-tab-pane v-if="!disableSMSLogin" key="withPhone" :tab="$t('login.smsLogin')">
            <a-form
              class="login-form"
              ref="formRef"
              name="login-form"
              :model="loginInfo"
              :rules="loginRules"
              @finish="loginAction($event, true)">
              <a-form-item name="phone">
                <a-input
                  v-model:value="loginInfo.phone"
                  autocomplete="off"
                  :placeholder="$t('login.mobilePhoneNumber')">
                  <template #prefix>
                    <div style="color: rgba(0,0,0,0.4)">
                      +86
                    </div>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item
                name="smsCode">
                <a-input
                  v-model:value="loginInfo.smsCode"
                  :placeholder="$t('login.inputCode')"
                >
                  <template #suffix>
                    <div class="code-input-suffix">
                      <div class="divide-line"></div>
                      <span
                        @click="getCode"
                        v-if="canGetCode"
                        class="get-code-text">
                        {{$t('login.getCode')}}
                      </span>
                      <span
                        v-else
                        class="time-left-text">{{timeLeft}} {{$t('forgetPassword.second')}}</span>
                    </div>
                  </template>
                </a-input>
              </a-form-item>
              <a-form-item>
                <a-button style="width:100%;margin-top:8px" size="large" type="primary" html-type="submit">{{$t('login.login')}}</a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
        <div class="other-action" v-if="false">
          <div style="cursor: pointer;">
            忘记密码
          </div>
          <div style="cursor: pointer;">
            手机验证码登录
          </div>
        </div>
        <div class="other-action">
<!--          <div style="cursor: pointer;" @click="showForgetModal">-->
<!--            {{$t('login.forgetPsw')}}-->
<!--          </div>-->
          <div style="cursor: pointer;">
            <span style="margin-right:6px">
              <GlobalOutlined />
            </span>
            <a-dropdown :trigger="['click']">
              <span>
                {{langStr}}
              </span>
              <template #overlay>
                <a-menu v-model:selectedKeys="selectedLang" selectable @select="handleLangSelect">
                  <a-menu-item key="zhCN">
                    简体中文
                  </a-menu-item>
                  <a-menu-item key="zhTW">
                    繁體中文
                  </a-menu-item>
                  <a-menu-item key="enUS">
                    English
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div style="cursor: pointer;" @click="showForgetModal">
            {{$t('forgetPassword.formTitle')}}
          </div>
        </div>
        <div class="other-login-way" v-if="false">
          <div style="padding:8px 0" class="wechat-login">
            <a-button style="width:100%" size="large" color="#36C518">
              <template #icon>
                <WechatOutlined />
              </template>
              微信登录
            </a-button>
          </div>
          <div style="padding:8px 0" class="oss-login">
            <a-button style="width:100%" size="large">
              <template #icon>
                <AntCloudOutlined />
              </template>
              OSS登录
            </a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="sign-in-con" v-if="false">
      <span class="sign-in-title">没有账号?</span>
      <span class="sign-in-value">免费注册</span>
    </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, reactive, toRefs, nextTick, ref, computed } from 'vue'
import { WechatOutlined, AntCloudOutlined, GlobalOutlined } from '@ant-design/icons-vue'
import { COMMON } from '@/store/type'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import api from '@/services/api'
import { message } from 'ant-design-vue'
import config from '@/config'
export default defineComponent({
  name: 'LoginBox',
  components: {
    WechatOutlined,
    AntCloudOutlined,
    GlobalOutlined
  },

  setup (props, context) {
    const store = useStore()
    const { t } = useI18n()

    const formRef = ref(null)

    // 当前选中的登录类型，默认账号密码登录
    const activeLoginType = ref('withEmail')
    // 是否禁用短信相关逻辑(绑定邮箱, 绑定手机)
    const disableSMSLogin = computed(() => config.DISABLE_SMS_LOGIN === 'true')

    const state = reactive({
      selectedLang: null,
      langStr: '',
      loginInfo: {
        // user: '',
        // password: ''
      },
      canGetCode: true,
      timeLeft: 0,
      welcomeTip: config.WELCOME_TIP
    })

    const checkUser = async (rule, value) => {
      if (!value || value === '') {
        return Promise.reject(new Error(t('login.enterAccount')))
      } else {
        return Promise.resolve()
      }
    }

    const getCode = async () => {
      try {
        await formRef.value.validate(['phone'])
      } catch (error) {
        console.error(error)
        return
      }
      try {
        const res = await api.getCodeWhenLogin({
          areaCode: '+86',
          number: state.loginInfo.phone
        })
        if (res && res.code === 200) {
          message.success(t('login.sendSuccess'))
        } else {
          if (res.detail) {
            message.error(res.detail)
            return
          }
        }
      } catch (error) {
        return
      }
      state.canGetCode = false
      state.timeLeft = 120
      const interval = setInterval(() => {
        state.timeLeft -= 1
        if (state.timeLeft <= 0) {
          state.canGetCode = true
          clearInterval(interval)
        }
      }, 1000)
    }

    const checkPass = async (rule, value) => {
      if (!value || value === '') {
        return Promise.reject(new Error(t('login.pswNullError')))
      } else {
        return Promise.resolve()
      }
    }

    const checkPhone = async (rule, value) => {
      var phoneReg = /0?(1)[0-9]{10}/
      if (!value || value === '') {
        return Promise.reject(new Error(t('login.mobilePhoneNumber')))
      } else if (!phoneReg.test(value)) {
        return Promise.reject(new Error(t('login.phoneErrorRule')))
      } else {
        return Promise.resolve()
      }
    }

    const checkVerificationCode = async (rule, value) => {
      if (!value || value === '') {
        return Promise.reject(new Error(t('login.codeErrorRule')))
      } else if (value && value.length < 6) {
        return Promise.reject(new Error(t('login.codeErrorRule')))
      } else {
        return Promise.resolve()
      }
    }

    const loginRules = {
      user: [
        {
          require: true, validator: checkUser, trigger: 'change'
        }
      ],
      password: [
        {
          require: true, validator: checkPass, trigger: 'change'
        }
      ],
      phone: [
        {
          require: true, validator: checkPhone, trigger: 'change'
        }
      ],
      verificationCode: [
        {
          require: true, validator: checkVerificationCode, trigger: 'change'
        }
      ]
    }

    const loginAction = (values, isPhone) => {
      context.emit('login', values, isPhone)
    }

    const getLocale = () => {
      let locale = ''
      const localeLocal = localStorage.getItem('locale')
      if (localeLocal) {
        locale = localeLocal
      } else {
        const language = (navigator.language ? navigator.language : navigator.userLanguage) || 'zh-CN'
        const languageSplit = language.split('-')
        locale = languageSplit && languageSplit.length > 1 ? languageSplit.join('') : 'zhCN'
      }
      let localeStr = ''
      switch (locale) {
        case 'zhCN':
          localeStr = '简体中文'
          break
        case 'zhTW':
          localeStr = '繁體中文'
          break
        case 'enUS':
          localeStr = 'English'
          break
      }
      state.langStr = localeStr
    }

    const handleLangSelect = ({ key }) => {
      store.dispatch(COMMON.SET_LOCALE, key)
      nextTick(() => {
        getLocale()
      })
    }

    const showForgetModal = () => {
      context.emit('showForgetModal')
    }

    onMounted(() => {
      if (store.state.locale) state.selectedLang = [store.state.locale]
      getLocale()
    })

    onBeforeUnmount(() => {
    })

    return {
      ...toRefs(state),
      activeLoginType,
      disableSMSLogin,
      loginRules,
      loginAction,
      handleLangSelect,
      showForgetModal,
      getCode,
      formRef
    }
  }
})
</script>

<style lang="less" scoped>
.code-input-suffix {
  width: 80px;
  text-align: center;
  .divide-line {
    border-left: solid 1px rgba(0,0,0,0.2);
    height: 30px;
    position: absolute;
    transform: translate(-10px, -50%);
    top: 50%;
  }
  .get-code-text {
    color: #209FFD;
    cursor: pointer;
  }
  .time-left-text {
    color: rgba(0,0,0,0.4);
    cursor: no-drop;
  }
}
.login-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 48px;
}
.other-action {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
    color: #209FFD;
    margin-bottom: 24px;
}
.sign-in-con {
    margin-top: 24px;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    background: #F8F8F8;
    font-size: 18px;
    font-weight: 400;
    .sign-in-title {
        color: rgba(0,0,0,0.6);
    }
    .sign-in-value {
        color: #209FFD;
        margin-left: 10px;
        cursor: pointer;
    }
}
.login-model {
  padding: 88px 48px 0;
}
</style>
<style lang="less">
.login-form {
  .ant-form-item-control-input {
    align-items: flex-end;
    min-height: 48px;
  }
  .ant-input, .ant-input-affix-wrapper{
    padding: 13px 0px;
    border-style: none none solid;
    border-radius: 0px;
    &:focus {
      box-shadow: 0 2px 4px -2px rgba(24, 144, 255, 0.2);
    }
  }
}
.login-model {
  .ant-btn-lg {
    height: 48px;
    padding: 6.4px 12px;
    font-size: 16px;
    border-radius: 8px;
  }
}
.wechat-login {
  .ant-btn {
    color: #36C518;
    background: #fff;
    border-color: #36C518;
    &:focus {
      box-shadow: 0 0 0 2px #35c5185d;
    }
    &:hover {
      box-shadow: 0 0 0 2px #35c5185d;
    }
  }
}
.oss-login {
  .ant-btn {
    color: #44A4F8;
    background: #fff;
    border-color: #44A4F8;
    &:focus {
      box-shadow: 0 0 0 2px #44A4F85d;
    }
    &:hover {
      box-shadow: 0 0 0 2px #44A4F85d;
    }
  }
}
</style>
